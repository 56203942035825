.title-container {
    position: fixed;
    inset: 0;
    z-index: -1;
    pointer-events: none;
    white-space: nowrap;
}

.message-title {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(calc(-100% - 5px));
    right: 0;
    color: white;
    text-align: center;
    font-size: 2rem;
    margin: 0px;
    padding-bottom: 0px;
}

.message-subtitle {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(10px);
    right: 0;
    text-align: center;
    color: white;
    font-size: 1rem;
    margin: 0px;
    padding: 0px;
}

.action-bar {
    position: fixed;
    bottom: 20%;
    left: 0;
    right: 0;
    font-size: 0.7rem;
    text-align: center;
}
